import React from 'react';

const Home = () => {
  return (
    <>
    <h1 id="title">Spend Less Money On Candles</h1>
    </>
  );
}

export default Home;
