// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table {
  display: flex;
  justify-content: space-evenly;
  padding-left: 5%;
  padding-right: 5%;
}

#title{
  padding-top: 5%;
  text-align: center;
}

.title h1 {
  align-content: center;
}

`, "",{"version":3,"sources":["webpack://./src/Products.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,6BAA6B;EAC7B,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":[".table {\n  display: flex;\n  justify-content: space-evenly;\n  padding-left: 5%;\n  padding-right: 5%;\n}\n\n#title{\n  padding-top: 5%;\n  text-align: center;\n}\n\n.title h1 {\n  align-content: center;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
