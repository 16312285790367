// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* body {
  margin: 0; 
} */

.container {
  width: 100%; /* Set container width to 100% of the viewport */
  max-width: 100%; /* Set a maximum width to prevent content from stretching beyond the viewport */
  overflow: hidden;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;;GAEG;;AAEH;EACE,WAAW,EAAE,gDAAgD;EAC7D,eAAe,EAAE,+EAA+E;EAChG,gBAAgB;AAClB","sourcesContent":["/* body {\n  margin: 0; \n} */\n\n.container {\n  width: 100%; /* Set container width to 100% of the viewport */\n  max-width: 100%; /* Set a maximum width to prevent content from stretching beyond the viewport */\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
