// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.product-card {
  width: 30%;
  height: 50%;
  margin: 10px;
  padding: 10px;
  border: 5px solid #ccc;

  /* Use flexbox to center content within the Product-card element */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.product-card iframe {
  width: 120px;
  height: 240px;
  /* object-fit: contain; */
}

.product-card p {
  margin: 0;
  padding-top: 5%;
  color:black
}

.product-card h1 {
  margin: 0;
  padding-bottom: 5%;
  color:black
}

`, "",{"version":3,"sources":["webpack://./src/ProductCard.css"],"names":[],"mappings":";AACA;EACE,UAAU;EACV,WAAW;EACX,YAAY;EACZ,aAAa;EACb,sBAAsB;;EAEtB,kEAAkE;EAClE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,yBAAyB;AAC3B;;AAEA;EACE,SAAS;EACT,eAAe;EACf;AACF;;AAEA;EACE,SAAS;EACT,kBAAkB;EAClB;AACF","sourcesContent":["\n.product-card {\n  width: 30%;\n  height: 50%;\n  margin: 10px;\n  padding: 10px;\n  border: 5px solid #ccc;\n\n  /* Use flexbox to center content within the Product-card element */\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n}\n\n.product-card iframe {\n  width: 120px;\n  height: 240px;\n  /* object-fit: contain; */\n}\n\n.product-card p {\n  margin: 0;\n  padding-top: 5%;\n  color:black\n}\n\n.product-card h1 {\n  margin: 0;\n  padding-bottom: 5%;\n  color:black\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
