import React from 'react';
import './ProductCard.css'

function ProductCard(props) {
  return (
    <div className="product-card">
      <h1>{props.title}</h1>
      <h1>{props.header}</h1>
      <iframe src={props.iframe_src}/>
      <p>{props.description}</p>
    </div>
  );
}

export default ProductCard;