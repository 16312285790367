import React from 'react';
import './Table.css';
import './Products.css';
import ProductCard from './ProductCard';

function Products(props) {

  var headers = ["Budget Friendly", "Luxury"]

  var itr = -1

  return (
    <div>
     <h1 id="title">{props.product.name}</h1>
      <table className="table">
        {props.product.content.map((attribute) => {
          itr += 1
          var header = headers[itr]
          return <ProductCard
          header={header}
          iframe_src={attribute.iframe_src}
          description={attribute.description}
        />
})}
      </table>
    </div>
  );
}

export default Products;
