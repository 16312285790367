import React from 'react';
import Home from './home';
import './NavBar.css';
import Products from './Products';

const hash = new Map()

const products_json = require('./assets/products.json');

function blobify(name) {
  return name.split(" ").join("-").toLowerCase()
}

function fill_hash() {
  products_json.map((product) => {
    var name_url_format = "/" + blobify(product.name)
    hash.set(name_url_format, product.name)
  })
}

const NavBar = () => {
  var path = window.location.pathname
  
  fill_hash()

  const not_home_path = path.length > 1

  return (
    <>
      <ul className="sidebar">
        {products_json.map((product) => (
        <>
          <li><a href={blobify(product.name)}>{product.name}</a></li>
        </>
        ))}
      </ul>

      <div id={path}/>

      {
      not_home_path ?
        (<Products product={products_json.find((product) => product.name.match(hash.get(path)))} title={hash.get(path)}/>) : (<Home/>)
      } 
    </>
  );
}

export default NavBar;


