// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar {
  /* display: flex; */
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  right: 50;
  width: 10%;
  z-index: 1;
  background-color: #333;
  padding: 0 10px;
}

.sidebar li {
  list-style: none;
}

.sidebar li a {
  text-decoration: none;
  color: rgb(255, 255, 255);
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
  display: block;
}

.sidebar li a:hover {
  background-color: rgb(255, 255, 255);
  color: #333;
}

.sidebar li a:active {
  transform: translateY(2px);
}`, "",{"version":3,"sources":["webpack://./src/NavBar.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,8BAA8B;EAC9B,eAAe;EACf,MAAM;EACN,OAAO;EACP,SAAS;EACT,UAAU;EACV,UAAU;EACV,sBAAsB;EACtB,eAAe;AACjB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;EACrB,yBAAyB;EACzB,kBAAkB;EAClB,cAAc;EACd,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,oCAAoC;EACpC,WAAW;AACb;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":[".sidebar {\n  /* display: flex; */\n  justify-content: space-between;\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 50;\n  width: 10%;\n  z-index: 1;\n  background-color: #333;\n  padding: 0 10px;\n}\n\n.sidebar li {\n  list-style: none;\n}\n\n.sidebar li a {\n  text-decoration: none;\n  color: rgb(255, 255, 255);\n  padding: 10px 20px;\n  font-size: 1em;\n  cursor: pointer;\n  display: block;\n}\n\n.sidebar li a:hover {\n  background-color: rgb(255, 255, 255);\n  color: #333;\n}\n\n.sidebar li a:active {\n  transform: translateY(2px);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
